.form-group {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 32px;

    &:not(:last-child) {
        margin-bottom: 32px;
    }

    &>span,
    .form-field {
        white-space: nowrap;
        display: block;

        &:not(:first-child):not(:last-child) {
            border-radius: 0;
        }

        &:first-child {
            border-radius: 6px 0 0 6px;
        }

        &:last-child {
            border-radius: 0 6px 6px 0;
        }

        &:not(:first-child) {
            margin-left: -1px;
        }
    }

    .form-field {
        position: relative;
        z-index: 1;
        flex: 1 1 auto;
        width: 1%;
        margin-top: 0;
        margin-bottom: 0;
        display: block;
        width: 100%;
        padding: 8px 16px;
        line-height: 25px;
        font-size: 14px;
        font-weight: 500;
        font-family: inherit;
        border-radius: 6px;
        -webkit-appearance: none;
        color: var(--input-color);
        border: 1px solid var(--input-border);
        background: var(--input-background);
        transition: border .3s ease;

        &::placeholder {
            color: var(--input-placeholder);
        }

        &:focus {
            outline: none;
            border-color: var(--input-border-focus);
        }
    }

    &>span {
        text-align: center;
        padding: 8px 12px;
        font-size: 14px;
        line-height: 25px;
        color: var(--group-color);
        background: var(--group-background);
        border: 1px solid var(--group-border);
        transition: background .3s ease, border .3s ease, color .3s ease;
    }

    &:focus-within {
        &>span {
            color: var(--group-color-focus);
            background: var(--group-background-focus);
            border-color: var(--group-border-focus);
        }
    }
}