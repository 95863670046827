html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: inherit;
  &:before,
  &:after {
      box-sizing: inherit;
  }
}

// Center
body {
  min-height: 100vh;
  font-family: 'Mukta Malar', Arial;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F5F9FF;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
